<template>
  <section class="container mx-auto py-6 md:py-10">
    <h2 class="section-heading sm:w-4/5 md:w-2/3 lg:w-1/2 mx-auto text-center">
      Strategic Digital Solutions for Your Businesses
    </h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-15 gap-y-10 pt-8 pb-10">
      <template v-if="isLoading">
        <div v-for="i in 1" :key="i" class="flex">
          <UtilsSkeletonLoading class="w-6 h-7" />
          <div class="ml-4 flex-1">
            <UtilsSkeletonLoading class="w-full mb-2 h-7" />
            <UtilsSkeletonLoading class="w-full h-24" />
          </div>
        </div>
      </template>
      <template v-else>
        <div v-for="category in categoriesData" :key="category.id">
          <div class="flex items-start">
            <div class="icon pt-1 w-7">
              <NuxtImg
                format="webp"
                srcset=""
                loading="lazy"
                class="w-7 h-full"
                :src="category.attributes?.icon?.data?.attributes?.url"
                :alt="category.attributes?.title || 'Expertise Icon'"
                :title="category.attributes?.title || 'Expertise Icon'"
              />
            </div>
            <div class="ml-5 flex-1">
              <h3 class="font-medium text-lg mb-2 text-bw-900">
                {{ category.attributes?.title }}
              </h3>
              <div
                class="text-base text-bw-800 description"
                v-html="category.attributes?.description"
              ></div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="mx-auto text-center">
      <NuxtLink
        to="/expertises"
        title="Expertises"
        class="px-3 py-[10px] sm:py-3 sm:px-5 text-sm sm:text-base border border-[#FF6700] rounded-md text-[#FF6700] block w-max mx-auto hover:bg-[#FF6700] hover:text-white"
      >
        Explore Our Expertise
      </NuxtLink>
    </div>
  </section>
</template>

<script setup>
// VARIABLE DECLARATION
const isLoading = ref(true)

const categoriesData = ref([])

// METHODS
const getCategories = async () => {
  const { data, error, pending } = await useSuitmediaApiData(
    '/api/expertice-categories',
    {
      query: {
        'pagination[pageSize]': 4,
        'populate[icon][fields][0]': 'url',
        'fields[0]': 'id',
        'fields[1]': 'title',
        'fields[2]': 'description',
        'sort[0]': 'order'
      },
      retry: 3,
      retryDelay: 300
    }
  )

  isLoading.value = pending.value

  if (error.value) {
    throw createError({
      statusCode: 404,
      message: `Categories does not exist`
    })
  }

  return data.value
}

// FIRST LOAD DATA
const categories = await getCategories()
categoriesData.value = categories.data
</script>

<style lang="scss" scoped>
:deep(.nuxt-icon) {
  svg {
    width: 20px;
    height: 20px;

    @media screen and (min-width: 640px) {
      width: 24px;
      height: 24px;
    }
  }
}

:deep(.description) {
  strong {
    font-weight: 500 !important;
  }
}
</style>
